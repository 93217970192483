import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "vant/lib/index.css"
import 'amfe-flexible/index.js'
import less from 'less'
import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)
// Vue.use(less)
// appId
let vueMain = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vueMain