import {
    postRequest,
    getRequest
} from "./request";
// token验证
export const checkToken = data => postRequest("/people/people/checkToken", data, false);
// 微信登陆
export const wechatLogin = data => postRequest("/api/wechat/login", data, false);
export const getWxConfig = data => postRequest("/api/wechat/config", data, false);
// 用户添加
export const peopleCreate = data => postRequest("/api/people/create", data, true);
// 分类
export const categoryList = data => postRequest("/api/category/lists", data, false);
// 登陆
export const login = data => postRequest("/people/people/login", data, false);
// 用户详情
export const detail = data => postRequest("/api/user/detail", data, true);
// 社区列表
export const communityAll = data => postRequest("/api/community/all", data, false);
// 片区列表
export const gridAll = data => postRequest("/api/grid/all", data, false);
// 网格列表
export const buildingAll = data => postRequest("/api/building/all", data, false);
// 上传图片
export const uploadImage = data => postRequest("/api/upload/image", data, false);
// 我的列表
export const myList = data => postRequest("/api/people/myList", data, true);
// 我的列表详情
export const myListDetail = data => postRequest("/api/people/detail", data, true);
