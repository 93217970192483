import {
    getWxConfig
} from "@/api/apiData";
var wx = require("weixin-js-sdk");
export default {
    data() {
        return {
            appId: '',
            jssdk_share_image:'',
            jssdk_share_title:'',
            jssdk_share_desc:''
        }
    },
    methods: {
        // 获取微信配置
        getWechatConfig() {
            const that = this;
            getWxConfig({}, false).then((res) => {
                if (res.code == 1) {
                    that.appId = res.data.appId;
                    that.jssdk_share_title = res.data.jssdk_share_title
                    that.jssdk_share_image = res.data.jssdk_share_image
                    that.jssdk_share_desc = res.data.jssdk_share_desc
                    localStorage.setItem("appId", res.data.appId);
                    document.title = res.data.site_title
                    that.wechatShare(res.data);
                }
            });
        },
        // 分享设置
        wechatShare(data) {
            // console.log(data)
            var appId = data.appId;
            var timestamp = data.timestamp;
            var nonceStr = data.nonceStr;
            var signature = data.signature;
            var url = window.location.href;
            let imgUrl = data.jssdk_share_image
            var title = data.jssdk_share_title;
            var desc = data.jssdk_share_desc
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: appId, // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: nonceStr, // 必填，生成签名的随机串
                    signature: signature, // 必填，签名，见附录1
                    jsApiList: data.jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {
                    // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                    wx.updateAppMessageShareData({
                        title: title, // 分享标题
                        desc: desc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        },
                    });
                    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                    wx.updateTimelineShareData({
                        title: title, // 分享标题
                        desc: desc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        },
                    });
                    wx.onMenuShareAppMessage({
                        title: title, // 分享标题
                        desc: desc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: imgUrl, // 分享图标
                        success: function () {
                            // 设置成功
                        },
                    });
                });
            }
        },
    },
    created() {
    },
    mounted() {},
};