import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logins:1,
    business_id:1
  },
  mutations: {
    // 微信登录code刷新
    refreshCode(state, val) {
      //(val);
      state.logins = val
    },
    // 公司id
    reBusinessId(state, val) {
      //(val);
      state.business_id = val
    }
  },
  actions: {},
  modules: {}
})