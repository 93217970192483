import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
    path: "/",
    redirect: "/index"
  },{
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),//首页
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index"),//首页
  },
  {
    path: "/form",
    name: "form",
    component: () => import("@/views/form"),//预约详情
  },
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/list"),//列表
  },
  {
    path: "/info",
    name: "info",
    component: () => import("@/views/info"),//详情
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router